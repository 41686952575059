import React, { Suspense } from 'react';
import 'leaflet/dist/leaflet.css';
import Header from "../shared/Header";
import Footer from "../shared/Footer";

// @ts-ignore
function PagePrice() {
    document.title = 'Price - mufleet';

    return (
        <Suspense
            fallback={
                <p>
                    loading ...
                </p>
            }
        >
            <div className="container">
                <Header/>
                <div className="row">
                            <div className="col-md-4">
                            <div className="card mb-4 rounded-3 shadow-sm">
                              <div className="card-header py-3">
                                <h4 className="my-0 fw-normal">Free</h4>
                              </div>
                              <div className="card-body">
                                  <br/>
                                <h1 className="card-title pricing-card-title">0 Naira<small className="text-muted fw-light">/device</small></h1>
                                  <br/>
                                <ul className="list-unstyled mt-3 mb-4">
                                  <li>Only one device</li>
                                  <li>Only one user</li>
                                  <li>No Email support</li>
                                  
                                </ul>
                              </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="card mb-4 rounded-3 shadow-sm">
                              <div className="card-header py-3">
                                <h4 className="my-0 fw-normal">Monthly Pro</h4>
                              </div>
                              <div className="card-body">
                                  <br/>
                                <h1 className="card-title pricing-card-title">5000 Naira<small className="text-muted fw-light">/device</small></h1>
                                  <br/>
                                <ul className="list-unstyled mt-3 mb-4">
                                  <li>Unlimited devices</li>
                                  <li>Unlimited users</li>
                                  <li>Email support</li>
                                  
                                </ul>
                              </div>
                            </div>
                        </div>
                            <div className="col-md-4">
                            <div className="card mb-4 rounded-3 shadow-sm">
                              <div className="card-header py-3">
                                <h4 className="my-0 fw-normal">Annual Pro</h4>
                              </div>
                              <div className="card-body">
                                  <br/>
                                <h1 className="card-title pricing-card-title">50000 Naira<small className="text-muted fw-light">/device</small></h1>
                                  <br/>
                                <ul className="list-unstyled mt-3 mb-4">
                                  <li>Unlimited devices</li>
                                  <li>Unlimited users</li>
                                  <li>Email support</li>

                                </ul>
                              </div>
                            </div>
                        </div>
                </div>
                <Footer/>
            </div>
        </Suspense>
    );
}

export default PagePrice;
