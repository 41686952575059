import React, { Suspense } from 'react';
import 'leaflet/dist/leaflet.css';
import Header from "../shared/Header";
import Footer from "../shared/Footer";

// @ts-ignore
function PageWelcome() {
    document.title = 'Welcome - mufleet';

    return (
        <Suspense
            fallback={
                <p>
                    loading ...
                </p>
            }
        >
            <div className="container">
                <Header/>
                <div className="row">
                    <div className="col-md-12 text-center">
                        <p><b>Mufleet</b> is a fleet visibility platform 🌍</p>
                        <p>Get up and running in a few steps 🚀:</p>
                        <br/>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-4">
                        <div className="card text-white text-center bg-primary mb-3">
                          <div className="card-header"><b>1 - Download and install the driver app (Android)</b></div>
                          <div className="card-body">
                              <p>Click on the image below to download</p>
                              <br/>
                            <a href='https://play.google.com/store/apps/details?id=com.mufleet'
                               target='_blank' rel="noreferrer"><img width="100%"
                                                                     src='https://ik.imagekit.io/mufleet/www/img/google_play_64.png'
                                                                     alt='google play'/></a>
                          </div>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="card text-white text-center bg-primary mb-3">
                          <div className="card-header"><b>2 - Share your location with the app</b></div>
                          <div className="card-body">
                              <p>Choose to share your location while using the app</p>
                            <img width="50%" src='https://ik.imagekit.io/mufleet/www/img/share.png' alt='share'/>
                          </div>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="card text-white text-center bg-primary mb-3">
                          <div className="card-header"><b>3 - Keep the app opened at all time</b></div>
                          <div className="card-body">
                              <p>Click to keep awake</p>
                              <p>This prevents the app from sleeping</p>
                              <img width="50%" src='https://ik.imagekit.io/mufleet/www/img/keep-awake.png'
                                   alt='keep-awake'/>
                          </div>
                        </div>
                    </div>
                </div>
                 <div className="row">
                      <div className="col-md-4">
                        <div className="card text-white text-center bg-primary mb-3">
                          <div className="card-header"><b>4 - Verify the app status</b></div>
                          <div className="card-body">
                              <p>The signal is green</p>
                              <p>The last updated value is not 'Never'</p>
                              <p>This might take a minute to refresh</p>
                               <img width="100%" src='https://ik.imagekit.io/mufleet/www/img/verify.png' alt='verify'/>
                          </div>
                        </div>
                    </div>
                      <div className="col-md-4">
                        <div className="card text-white text-center bg-primary mb-3">
                          <div className="card-header"><b>5 - Note down the code generated by the driver app</b></div>
                          <div className="card-body">
                              <p>Click to copy your pairing code</p>
                              <p>Share it with the preferred communication mean indicated by your company</p>
                            <img width="100%" src='https://ik.imagekit.io/mufleet/www/img/code.png' alt='code'/>
                          </div>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="card text-white text-center bg-primary mb-3">
                          <div className="card-header"><b>6 - Register and log into the web app</b></div>
                          <div className="card-body">
                              <br/>
                              <h5><a href="https://app.mufleet.com" target={"_blank"}
                                     rel="noreferrer">https://app.mufleet.com</a></h5>
                              <br/>
                              <p>The web app is a visualization system which contains all your devices with their positions on a dynamically updated map</p>
                          </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-4">
                        <div className="card text-white text-center bg-primary mb-3">
                          <div className="card-header"><b>7 - Register a new device</b></div>
                          <div className="card-body">
                            <h5><a href="https://app.mufleet.com/device" target={"_blank"}
                                   rel="noreferrer">https://app.mufleet.com/device</a></h5>
                              <br/>
                              <p>Add the code generated by the driver app to the list of devices</p>
                          </div>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="card text-white text-center bg-primary mb-3">
                          <div className="card-header"><b>8 - Enrich device with properties (Optional)</b></div>
                          <div className="card-body">
                              <h5><a href="https://app.mufleet.com/property " target={"_blank"}
                                     rel="noreferrer">https://app.mufleet.com/property</a></h5>
                              <h5><a href="https://app.mufleet.com/device-management" target={"_blank"}
                                     rel="noreferrer">https://app.mufleet.com/device-management</a></h5>
                              <p>Optionally, add some additional properties to the device</p>
                              <p>Properties are used in order to filter out the devices</p>
                              <p>Example: driver name, telephone number, team name, email address ...</p>
                          </div>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="card text-white text-center bg-primary mb-3">
                          <div className="card-header"><b>9 - Relax and monitor</b></div>
                          <div className="card-body">
                              <p>Enjoy full real time visibility of your device</p>
                              <img width="100%" src='https://ik.imagekit.io/mufleet/www/img/map.png' alt='map'/>
                          </div>
                        </div>
                    </div>
                </div>
                <br/>
                <hr/>
                <br/>
                <div className="row">
                    <div className="col-md-12">
                        <div className="card text-white text-center bg-primary mb-3">
                          <div className="card-header"><b>About reverse geolocation</b></div>
                          <div className="card-body">
                              <p>We are currently providing reverse geolocation in the following countries: </p>

                                 <p> <b>Nigeria - Ethiopia</b></p>

                              <p>If you need reverse geolocation for a business in another country, please write to <b>help@mufleet.com</b></p>
                          </div>
                        </div>
                    </div>
                </div>
                <Footer/>
            </div>
        </Suspense>
);
}

export default PageWelcome;
