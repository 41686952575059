import React from 'react';
function Header() {

    return (
        <div>
            <nav className="navbar navbar-expand-lg navbar-light">
                <a className="navbar-brand" href="/">
                    &nbsp;&nbsp;&nbsp;
                    <img src="https://ik.imagekit.io/mufleet/www/icons/48.png" alt="48.png" />
                </a>
                <a className="navbar-brand" href="/">
                    <h1 className="fs-1">mufleet</h1>
                </a>
                <button
                    className="navbar-toggler"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#navbarSupportedContent"
                    aria-controls="navbarSupportedContent"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                >
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                        <li className="nav-item">
                        </li>
                    </ul>
                    <a href="https://app.mufleet.com" target={"_blank"} rel="noreferrer">
                        <button className="btn btn-success">
                            Start now
                        </button>
                    </a>
                </div>
            </nav>
            <br />
        </div>
    );
}

export default Header;
