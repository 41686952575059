import React, { Suspense } from 'react';
import Header from '../shared/Header';
import Footer from "../shared/Footer";
function Page404() {
    return (
        <Suspense
            fallback={
                <p>
                    Loading ...
                </p>
            }
        >
            <div className="container">
                <Header />
                <div className="col-md-12">
                    <br />
                    <br />
                    <br />
                    <h1 className="text-lg-center">
                        Not found
                    </h1>
                    <br />
                    <h1 className="text-lg-center">
                        <img src="404.png" width="50%" alt="error" />
                    </h1>
                    <br />
                    <br />
                    <br />
                </div>
                <Footer/>
            </div>
        </Suspense>
    );
}

export default Page404;
